/* eslint-disable consistent-return */
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

interface TimerState {
  seconds: number;
  minutes: number;
  hours: number;
}

interface LastUpdate {
  date?: Date;
  planningId?: number;
  isRunning: boolean;
}

interface TimerContextData {
  seconds: number;
  minutes: number;
  hours: number;
  isRunning: boolean;
  start(planningId: number, time_lapsed?: string): void;
  pause(): void;
  reset(): void;
}

const TimerContext = createContext<TimerContextData>({} as TimerContextData);

const TimerProvider: React.FC = ({ children }) => {
  const [lastUpdateActivity, setlastUpdateActivity] = useState<LastUpdate>(
    () => {
      const lastUpdate = localStorage.getItem('@MyIPAC:timerLastUpdate');

      if (lastUpdate) {
        return JSON.parse(lastUpdate);
      }

      return {};
    },
  );
  const [timer, setTimer] = useState<TimerState>(() => {
    const timerSorage = localStorage.getItem('@MyIPAC:timer');

    if (timerSorage) {
      return JSON.parse(timerSorage);
    }

    return {
      seconds: 0,
      minutes: 0,
      hours: 0,
    };
  });
  const [isRunning, setIsRunning] = useState(
    lastUpdateActivity.isRunning || false,
  );

  useEffect(() => {
    // exit early when we reach 0
    if (!isRunning) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setTimeout(() => {
      let seconds;
      let minutes;
      let hours;

      if (timer.seconds < 59) {
        seconds = timer.seconds + 1;
        minutes = timer.minutes;
      } else {
        seconds = 0;
        minutes = timer.minutes + 1;
      }

      if (minutes === 60) {
        minutes = 0;
        hours = timer.hours + 1;
      } else {
        hours = timer.hours;
      }

      setTimer({
        hours,
        minutes,
        seconds,
      });

      localStorage.setItem(
        '@MyIPAC:timer',
        JSON.stringify({ hours, minutes, seconds }),
      );
      localStorage.setItem(
        '@MyIPAC:timerLastUpdate',
        JSON.stringify({
          ...lastUpdateActivity,
          date: new Date(),
        }),
      );
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timer, isRunning, lastUpdateActivity]);

  const start = useCallback((planningId: number, time_lapsed?: string) => {
    setIsRunning(true);
    if (time_lapsed) {
      const timeArray = time_lapsed.split(':');
      setTimer({
        hours: Number(timeArray[0]),
        minutes: Number(timeArray[1]),
        seconds: Number(timeArray[2]),
      });
    }
    setlastUpdateActivity({
      date: new Date(),
      planningId,
      isRunning: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pause = useCallback(() => {
    setIsRunning(false);
    setlastUpdateActivity({
      ...lastUpdateActivity,
      isRunning: false,
    });
    localStorage.setItem(
      '@MyIPAC:timerLastUpdate',
      JSON.stringify({
        ...lastUpdateActivity,
        isRunning: false,
      }),
    );
  }, [lastUpdateActivity]);

  const reset = useCallback(() => {
    setIsRunning(false);
    setTimer({
      seconds: 0,
      minutes: 0,
      hours: 0,
    });
    localStorage.removeItem('@MyIPAC:timer');
    localStorage.removeItem('@MyIPAC:timerLastUpdate');
  }, []);

  return (
    <TimerContext.Provider
      value={{
        seconds: timer.seconds,
        minutes: timer.minutes,
        hours: timer.hours,
        isRunning,
        start,
        pause,
        reset,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

function useTimer(): TimerContextData {
  const context = useContext(TimerContext);

  if (!context) {
    throw new Error('useTimer must be used within an TimerProvider');
  }

  return context;
}

export { TimerProvider, useTimer };
