import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  Fragment,
} from 'react';

import {
  Box,
  Text,
  Flex,
  Container,
  Button,
  Image,
  useToast,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Company, Personal } from '~/@types/Survey';
import logoImg from '~/assets/logo-form.png';
import InputChakra from '~/shared/components/InputChakra';
import CheckboxInput from '~/shared/components/InputChakra/CheckboxChakra';
import RadioChakra from '~/shared/components/InputChakra/RadioChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import SectionHeader from '~/shared/components/SectionHeader';
import api from '~/shared/services/api';
import { getValidationErrorWithFocus } from '~/utils/getValidationErrors';

interface StructureFamily {
  name: string;
  relative_id: string;
  civil_status_id: string;
  age: string;
  visible?: boolean;
  id: number;
}

interface Properties {
  property_type_id: string;
  estimated_value: string;
  city: string;
  uf: string;
  owner_the_property: string;
  monthly_income_amount: string;
}

interface SurveyOptions {
  id: number;
  name: string;
  label: string;
  value: string;
  visible?: boolean;
}
interface DataForm {
  personal: Personal;
  structure_family: StructureFamily;
  properties: Properties;
  company: Company;
}

interface Survey {
  relativies: SurveyOptions[];
  civil_status: SurveyOptions[];
  property_types: SurveyOptions[];
  tributary_regimes: SurveyOptions[];
}

const ConsultativeAnalysis: React.FC = () => {
  const addToast = useToast();
  const formRef = useRef<FormHandles>(null);
  const [toggleQuestions, setToggleQuestions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [company, setCompany] = useState<Company[]>([
    {
      branch_activity: '',
      name: '',
      monthly_billing: '',
      number_branches: '',
      tributary_regime_id: '',
    },
  ]);

  const [relativie, setRelativie] = useState<SurveyOptions[]>([]);
  const [civilStatus, setCivilStatus] = useState<SurveyOptions[]>([]);
  const [propertyTypes, setPropertyTypes] = useState<SurveyOptions[]>([]);
  const [tributaryRegime, setTributaryRegime] = useState<SurveyOptions[]>([]);

  const responsibilities = [
    { label: 'Sócio', value: '1' },
    { label: 'Gestor', value: '2' },
    { label: 'Técnico', value: '3' },
  ];

  const subjectInterest = [
    { id: '1', label: 'Tributário', value: '1' },
    { id: '2', label: 'Trabalhista', value: '2' },
    { id: '3', label: 'Societário', value: '3' },
    { id: '4', label: 'Contábil', value: '4' },
  ];

  const questions = [
    {
      id: '1',
      label: 'Preciso de uma contabilidade mais consultiva.',
      value: '1',
    },
    {
      id: '2',
      label:
        'A contabilidade atual não consegue mais me atender por conta do meu faturamento (regime tributário) ou por conta do seguimento de atuação da minha empresa ou porque sinto que a contabilidade está defasada tecnicamente.',
      value: '2',
    },
    { id: '3', label: 'Busco por honorários mais baratos.', value: '3' },
    {
      id: '4',
      label: 'Não consigo esclarecer dúvidas com a minha contabilidade.',
      value: '4',
    },
    {
      id: '5',
      label:
        'Não confio mais nas informações e informes gerados por minha contabilidade atual.',
      value: '5',
    },
  ];

  useEffect(() => {
    setLoading(true);
    api
      .get<Survey>(`/survey/filters`)
      .then((response) => {
        const {
          relativies,
          civil_status,
          property_types,
          tributary_regimes,
        } = response.data;
        setRelativie(
          relativies.map((rel) => {
            return { ...rel, label: rel.name, value: rel.id.toString() };
          }),
        );
        setCivilStatus(
          civil_status.map((cs) => {
            return { ...cs, label: cs.name, value: cs.id.toString() };
          }),
        );
        setPropertyTypes(
          property_types.map((pt) => {
            return { ...pt, label: pt.name, value: pt.id.toString() };
          }),
        );
        setTributaryRegime(
          tributary_regimes.map((tr) => {
            return { ...tr, label: tr.name, value: tr.id.toString() };
          }),
        );
      })
      .finally(() => setLoading(false));
  }, []);

  const handleAddCompany = useCallback(() => {
    setCompany((state) => [
      ...state,
      {
        branch_activity: '',
        name: '',
        monthly_billing: '',
        number_branches: '',
        tributary_regime_id: '',
      },
    ]);
  }, []);
  const handleRemoveCompany = useCallback(() => {
    setCompany((state) => state.filter((comp, i) => i !== company.length - 1));
  }, [company]);

  const handleSubmit = useCallback(
    async (dataSubmit: DataForm, { reset }) => {
      setLoadingSubmit(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          phone: Yup.string().required('Telefone obrigatório'),
          companies: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Nome da empresa obrigatório'),
              branch_activity: Yup.string().required(
                'Ramo da atividade obrigatório',
              ),
              tributary_regime_id: Yup.string().required(
                'Regime tributário obrigatório',
              ),
              responsibility_id: Yup.string().required('Cargo é obrigatório'),
              number_branches: Yup.string().required(
                'Quantidade de filiais obrigatório',
              ),
              monthly_billing: Yup.string().required(
                'Faturamento mensal da empresa obrigatório',
              ),
            }),
          ),
          subjects_interest: Yup.array().required(
            'Selecione o assunto de Interesse',
          ),
          radio: Yup.string().required('Selecione uma das opções'),
          reason_change_accounting: Yup.array().when('radio', {
            is: (val) => {
              return val === '1';
            },
            then: Yup.array().required('Campo obrigatório 2'),
            otherwise: Yup.array(),
          }),
        });

        await schema.validate(dataSubmit, {
          abortEarly: false,
        });

        await api.post<Survey>(`/survey/consultative-analysis`, dataSubmit);

        reset();

        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Formulário enviado!',
          description: `Formulário eviado com sucesso`,
          status: 'success',
        });

        setLoading(true);
        window.scrollTo(0, 0);
      } catch (err) {
        // console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrorWithFocus(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Erro ao tentar enviar formulário',
          description: err?.response?.data?.error || '',
          status: 'error',
        });
      } finally {
        setLoadingSubmit(false);
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <Container pos="relative">
      {loading && <LoadingAbsolute z_index={1111} min_height={500} />}
      {!loading && (
        <>
          <Box marginTop="20px">
            <Image
              width="100%"
              objectFit="contain"
              src={logoImg}
              alt="MyIPAC"
              mb={10}
            />
            <Flex justifyContent="center">
              <SectionHeader title="Análise Consultiva" />
            </Flex>
          </Box>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Box>
              <Container
                backgroundColor="#fff"
                border="1px solid rgb(218,220,224)"
                borderRadius="8px"
                position="relative"
                paddingTop="22px"
                padding="24px"
                marginBottom="12px"
                marginTop="12px"
              >
                <Flex justifyContent="center">
                  <Text marginBottom="10px" fontSize="4xl">
                    Dados Pessoais
                  </Text>
                </Flex>

                <InputChakra name="survey_id" type="hidden" defaultValue={2} />

                <InputChakra
                  name="name"
                  label="Nome"
                  background="#e9ecef"
                  borderColor=""
                  mb="0px"
                />

                <InputChakra
                  name="email"
                  label="E-mail"
                  background="#e9ecef"
                  borderColor=""
                  mb="0px"
                />

                <InputChakra
                  name="phone"
                  label="Telefone"
                  background="#e9ecef"
                  borderColor=""
                  mb="0px"
                  mask="phone"
                />
              </Container>
            </Box>
            <Box>
              {company.map((cp, idx) => {
                return (
                  <Fragment key={cp.name}>
                    <Container
                      backgroundColor="#fff"
                      border="1px solid rgb(218,220,224)"
                      borderRadius="8px"
                      position="relative"
                      paddingTop="22px"
                      padding="24px"
                      marginBottom="12px"
                      marginTop="12px"
                    >
                      {idx === 0 && (
                        <Flex justifyContent="center">
                          <Text fontSize="4xl" marginBottom="10px">
                            Empresas
                          </Text>
                        </Flex>
                      )}

                      <Text
                        color="#718096"
                        display="flex"
                        flexDirection="row-reverse"
                      >
                        {`Empresa (${idx + 1})`}
                      </Text>
                      <InputChakra
                        name={`companies[${idx}].name`}
                        label="Nome da Empresa"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        defaultValue={cp.name}
                      />
                      <InputChakra
                        name={`companies[${idx}].branch_activity`}
                        label="Ramo de Atividade"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        defaultValue={cp.branch_activity}
                      />
                      <SelectChakra
                        name={`companies[${idx}].tributary_regime_id`}
                        label="Regime Tributário"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        options={tributaryRegime}
                        placeholder="Selecione"
                        defaultValue={cp.tributary_regime_id}
                      />
                      <SelectChakra
                        name={`companies[${idx}].responsibility_id`}
                        label="Cargo"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        options={responsibilities}
                        placeholder="Selecione"
                        defaultValue={cp.tributary_regime_id}
                      />
                      <InputChakra
                        name={`companies[${idx}].number_branches`}
                        label="Quantidade de Filiais"
                        background="#e9ecef"
                        borderColor=""
                        mb="0px"
                        type="number"
                        defaultValue={cp.number_branches}
                      />
                      <InputChakra
                        name={`companies[${idx}].monthly_billing`}
                        label="Faturamento Mensal"
                        background="#e9ecef"
                        borderColor=""
                        // type="string"
                        mb="0px"
                        defaultValue={cp.monthly_billing}
                        leftAddon="R$"
                        mask="money"
                        maxLength={15}
                      />
                      <Flex justifyContent="end">
                        {company.length - 1 === idx && (
                          <Button
                            onClick={handleAddCompany}
                            colorScheme="green"
                            variant="ghost"
                            marginTop="10px"
                          >
                            + Empresas
                          </Button>
                        )}{' '}
                        {company.length > 1 && company.length - 1 === idx && (
                          <Button
                            onClick={handleRemoveCompany}
                            colorScheme="red"
                            variant="ghost"
                            marginTop="10px"
                          >
                            Excluir
                          </Button>
                        )}
                      </Flex>
                    </Container>
                  </Fragment>
                );
              })}

              <Container
                backgroundColor="#fff"
                border="1px solid rgb(218,220,224)"
                borderRadius="8px"
                position="relative"
                paddingTop="22px"
                padding="24px"
                marginBottom="12px"
                marginTop="12px"
              >
                <Flex justifyContent="center">
                  <Text fontSize="4xl" marginBottom="12px">
                    Assunto de Interesse
                  </Text>
                </Flex>
                <CheckboxInput
                  name="subjects_interest"
                  options={subjectInterest}
                />
              </Container>
              <Container
                backgroundColor="#fff"
                border="1px solid rgb(218,220,224)"
                borderRadius="8px"
                position="relative"
                padding="24px"
                my="12px"
                justifyContent="center"
              >
                <Text mb={4}>Você precisa ou quer mudar de contabilidade?</Text>
                <RadioChakra
                  isInline
                  justifyStack="center"
                  name="radio"
                  options={[
                    { label: 'Sim', value: '1' },
                    { label: 'Não', value: '0' },
                  ]}
                  onChangeCustom={(value) =>
                    value === '1'
                      ? setToggleQuestions(true)
                      : setToggleQuestions(false)
                  }
                />

                {toggleQuestions && (
                  <Box>
                    <CheckboxInput
                      name="reason_change_accounting"
                      options={questions}
                    />
                  </Box>
                )}
              </Container>

              <Flex justifyContent="center">
                <Button
                  m={4}
                  colorScheme="blue"
                  width="150px"
                  height="50px"
                  onClick={() => formRef.current?.submitForm()}
                  isLoading={loadingSubmit}
                >
                  Enviar
                </Button>
              </Flex>
            </Box>
          </Form>
        </>
      )}
    </Container>
  );
};

export default ConsultativeAnalysis;
